<template>
  <div class="container">
    <div class="page-title">
      <el-row>
        <el-col :span="18">
          <h3>机房与机柜</h3>
        </el-col>
        <el-col :span="6" style="text-align: right;">
          <el-button type="text" icon="el-icon-plus" @click="addCabPop=true">新增机柜</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="wrap">
      <div class="page-sider">
        <h4><span @click="addRoomPop=true"><i class="el-icon-plus"></i></span>机房</h4>
        <el-menu :default-active="curIndex" class="menu">
          <el-menu-item :index="(index + 1).toString()" class="menu-li" style="padding-left: 0;padding-right: 10px" v-for="(item,index) in roomList" :key="item.id" @click="getRoomDetail(item.id)">
            <template slot="title">
                            <span>
                                <i class="el-icon-edit-outline" @click="editRoom(item,index)"></i>
                                <i class="el-icon-delete" @click="deleteRoom(item.id, 1)"></i>
                            </span>
              <i class="roomname">{{item.name}}</i>
            </template>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="page-content">
        <el-card class="box-card" shadow="never">
          <el-row>
            <el-col :span="6">机房：{{roomInfo.name}}</el-col>
            <el-col :span="12">地址：{{roomInfo.address}}</el-col>
            <el-col :span="6">备注：{{roomInfo.note}}</el-col>
            <el-col :span="6">主机数：{{sumInfo.hosts}}</el-col>
            <el-col :span="6">安全设备：{{sumInfo.safes}}</el-col>
            <el-col :span="6">网络设备：{{sumInfo.networks}}</el-col>
            <el-col :span="6">其他设备：{{sumInfo.others}}</el-col>
          </el-row>
        </el-card>

        <div class="query-box">
          <el-row>
            <el-col :span="18">
              <el-form :inline="true" :model="queryParams" @submit.native.prevent>
                <el-form-item label="机柜名称" style="margin-bottom: 0">
                  <el-input placeholder="请输入机柜名称" clearable v-model="queryParams.keys" style="width: 280px;">
                  </el-input>
                </el-form-item>
                <el-form-item style="margin-bottom: 0">
                  <el-button type="primary" native-type="submit" @click="searchData"><i class="el-icon-search"></i> 查询</el-button>
                </el-form-item>
              </el-form>
            </el-col>
            <el-col :span="6" style="text-align: right">
              <el-button type="warning" @click="showImportPop=true"><i class="iconfont icon-daoru"></i> 导入</el-button>
              <el-button type="warning" @click="exportData"><i class="iconfont icon-export"></i> 导出</el-button>
            </el-col>
          </el-row>
        </div>

        <el-table :data="tableData" ref="multipleTable" v-loading="loading" element-loading-text="加载中..." style="width: 100%">
          <el-table-column label="序号" align="center" width="60">
            <template slot-scope="scope">
              {{scope.$index+1}}
            </template>
          </el-table-column>
          <el-table-column label="机柜名称" prop="name">
          </el-table-column>
          <el-table-column label="所属机房">
            <template>
              {{roomInfo.name}}
            </template>
          </el-table-column>
          <el-table-column label="主机数" prop="hosts" align="center">
          </el-table-column>
          <el-table-column label="安全设备" prop="safes" align="center">
          </el-table-column>
          <el-table-column label="网络设备" prop="networks" align="center">
          </el-table-column>
          <el-table-column label="其他设备" prop="others" align="center">
          </el-table-column>
          <el-table-column label="备注" prop="note">
          </el-table-column>
          <el-table-column label="操作" align="center" width="200">
            <template slot-scope="scope" v-if="scope.row.id != '-'">
              <span class="row-opt" @click="editData(scope.row)"><el-link type="primary" icon="el-icon-edit-outline">修改</el-link></span>
              <el-divider direction="vertical"></el-divider>
              <span class="row-opt" @click="deleteRoom(scope.row.id, 2)"><el-link type="primary" icon="el-icon-delete">删除</el-link></span>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagenation">
          <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="currentPage"
                  :page-sizes="[10, 20, 50]"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total">
          </el-pagination>
        </div>
      </div>
    </div>

    <el-dialog :title="popRoomTitle" :visible.sync="addRoomPop" :close-on-click-modal="false" @close="closePop">
      <el-form :model="roomForm" label-width="140px" @submit.prevent="submitRoom(1)" :close-on-click-modal="false">
        <el-form-item label="机房名称" prop="name">
          <el-input v-model="roomForm.name"></el-input>
        </el-form-item>
        <el-form-item label="机房地址" prop="address">
          <el-input v-model="roomForm.address"></el-input>
        </el-form-item>
        <el-form-item label="机房坐标（纬度）" prop="latitude">
          <el-input v-model="roomForm.latitude" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="机房坐标（经度）" prop="longitude">
          <el-input v-model="roomForm.longitude" style="width: 200px"></el-input>
          <p>点击获取坐标值：<a href="https://lbs.amap.com/tools/picker" target="_blank">https://lbs.amap.com/tools/picker</a></p>
        </el-form-item>
        <el-form-item label="备注" prop="note">
          <el-input v-model="roomForm.note" type="textarea" :rows="3"></el-input>
        </el-form-item>
        <div style="margin-top:30px; text-align: center">
          <el-button type="primary" native-type="submit" @click.prevent="submitRoom(1)">提交</el-button>
          <el-button @click="closePop">关闭</el-button>
        </div>
      </el-form>
    </el-dialog>

    <el-dialog :title="popCabTitle" :visible.sync="addCabPop" :close-on-click-modal="false" @close="closePop">
      <el-form :model="roomForm" label-width="140px" @submit.prevent="submitRoom(2)" :close-on-click-modal="false">
        <el-form-item label="机柜名称" prop="name">
          <el-input v-model="roomForm.name"></el-input>
        </el-form-item>
        <el-form-item label="所属机房">
          <el-select v-model="selRoomId" placeholder="请选择">
            <template v-for="item in roomList">
              <el-option :key="item.id" :label="item.name" :value="item.id"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="note">
          <el-input v-model="roomForm.note" type="textarea" :rows="3"></el-input>
        </el-form-item>
        <div style="margin-top:30px; text-align: center">
          <el-button type="primary" native-type="submit" @click.prevent="submitRoom(2)">提交</el-button>
          <el-button @click="closePop">关闭</el-button>
        </div>
      </el-form>
    </el-dialog>

    <el-dialog title="导入机柜" :visible.sync="showImportPop" :close-on-click-modal="false">
      <el-upload
              class="uploader"
              drag
              name="xlsx_file"
              :action="importExcel"
              :headers="headerObj"
              :show-file-list="false"
              :on-progress="uploading"
              :on-success="successUpload"
              :before-upload="beforeUpload"
              v-loading="waiting"
              element-loading-text="请稍等片刻>>>"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.7)"
              center>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将Excel文件拖到此处，或<em>点击导入</em></div>
        <div class="el-upload__tip" slot="tip">只能导入<span style="color: #f60">.xlsx</span>文件，且不超过2MB，请按照<a
                href="/muban/room.xlsx">模板格式</a>编辑好再导入
        </div>
      </el-upload>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: "App",
    data() {
      return {
        loading: true,
        curIndex: '1',
        selRoomId: null,
        roomList: [],
        queryParams: {
          keys: ''
        },
        sumInfo: {
          hosts: 0,
          safes: 0,
          networks: 0,
          others: 0
        },
        tableData: [],

        addRoomPop: false,
        popRoomTitle: '新增机房',
        roomForm: {},
        addCabPop: false,
        popCabTitle: '新增机柜',
        cabForm: {},
        roomInfo: {},
        currentPage: 1,
        pageSize: 10,
        total: 0,
        showImportPop: false,
        importExcel: this.$api.BaseUrl + this.$api.ImportRoomExcel,
        headerObj: {
          'access-token': sessionStorage.getItem('access-token')
        },
        waiting: false,
      }
    },
    created() {
      this.getRoomList(0);
    },
    methods: {
      getRoomList (roomId) {
        let params = {
          page: this.currentPage,
          limit: this.pageSize,
          room_id: roomId,
          keys: this.queryParams.keys
        }
        this.$get(this.$api.GetRoomList, params).then((res) => {
            if (roomId === 0) {
              this.roomList = res.data.list;
              this.selRoomId = this.roomList[0].id;
              this.getRoomDetail(this.selRoomId);
            } else {
              this.tableData = res.data.list;
              this.total = res.data.total_count;
            }
          this.sumInfo = res.data.sum;
            this.loading = false;
        });
      },
      getRoomDetail(roomId) {
        this.$get(this.$api.GetRoomInfo, {id: roomId}).then((res) => {
            this.roomInfo = res.data;
            this.selRoomId = roomId;
            this.getCabList(roomId);
        })
      },
      getCabList(roomId) {
        this.loading = true;
        this.getRoomList(roomId);
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.getRoomList(this.selRoomId)
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.getRoomList(this.selRoomId)
      },
      editRoom(item) {
        this.addRoomPop = true;
        this.roomForm = item;
        this.popRoomTitle = '修改机房';
      },
      submitRoom(type) {
        if (this.roomForm.name == '') {
          this.$message.warning('机房名称不能为空');
          return false;
        }
        if (type == 2) {
          if (this.selRoomId == '') {
            this.$message.warning('请选择所属机房');
            return false;
          }
          this.roomForm.room_id = this.selRoomId;
        }
        let postUrl = this.$api.AddRoom;
        if (this.roomForm.id) {
          postUrl = this.$api.EditRoom;
        }
        this.$post(postUrl, this.roomForm).then(() => {
            this.$message({
              message: '操作成功！',
              type: 'success'
            });
            if (type === 1) {
              this.getRoomList(0);
              this.addRoomPop = false;
            } else {
              this.getCabList(this.selRoomId);
              this.addCabPop = false;
            }
        });
      },
      deleteRoom(id,type) {
        this.$confirm('此操作将删除所选项, 是否继续?', '提示', {
          type: 'warning'
        }).then(() => {
          this.$post(this.$api.DeleteRoom, {id: id, type: type}).then(() => {
              this.$message({
                message: '操作成功！',
                type: 'success'
              });
              if (type == 1) {
                this.getRoomList(0);
              } else {
                this.getCabList(id);
              }
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },

      searchData() {
        this.loading = true;
        this.currentPage = 1;
        this.tableData = [];
        this.total = 0;
        this.getCabList(this.selRoomId);
      },

      editData(item) {
        this.addCabPop = true;
        this.popCabTitle = '修改机柜';
        this.$get(this.$api.GetRoomInfo, {id: item.id}).then((res) => {
            this.roomForm = res.data;
            //this.roomInfo.room_id = item.room_id;
        });
      },

      closePop() {
        this.addRoomPop = false;
        this.roomForm = {};
        this.popRoomTitle = '新增机房';
        this.addCabPop = false;
        this.popCabTitle = '新增机柜';
      },
      beforeUpload(file) {
        if (file.size / 1024 / 1024 > 2) {
          this.$message.error('Excel文件大小不能超过 2MB!');
          return false;
        }

        if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          return;
        } else {
          this.$message.error('只能上传.xlsx格式的Excel文件!');
          return false;
        }
      },
      successUpload(res, file) {
        console.log(file)
        this.waiting = false;
        if (res.code === 200) {
          this.$message.success('导入成功!');
          this.showImportPop = false;
          this.getListData();
        } else {
          this.$message.error(res.message);
        }
      },
      uploading(event, file) {
        console.log(event, file)
        this.waiting = true;
      },

      //导出Excel
      exportData() {
        this.queryParams.page = 'all';
        this.queryParams.responseType = 'blob';
        this.queryParams.room_id = this.selRoomId;
        this.$download(this.$api.ExportRoomExcel, this.queryParams).then((res) => {
          this.downloadExcel(res.data, '机柜.xlsx');
        });
      },
      //下载保存Excel文件
      downloadExcel(data, filename) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
        let a = document.createElement("a");
        let objUrl = URL.createObjectURL(blob);
        a.href = objUrl;
        a.download = filename //文件名
        a.click();
        URL.revokeObjectURL(objUrl); // 释放内存
      },
    }
  }
</script>

<style scoped lang="less">
  .query-box {
    background: #f6f6f6;
    padding: 10px;
    margin: 10px 0;
  }
  .pagenation{
    margin: 20px auto;
    text-align: center;
  }
  .page-sider{
    width: 200px;
    height: 100%;
    position: absolute;
    border-right: 1px solid #e6e6e6;
    h4{
      height: 30px;
      line-height: 30px;
      border-bottom: 1px solid #d3d3d3;
      margin-right: 6px;
      span{
        float: right;
        margin-right: 6px;
        cursor: pointer;
        i{
        }
      }
    }
    .menu{
      span{
        float: right;
        display: none;
      }
      i{
        font-style: normal;
      }
      i.roomname{
        width: 140px;
        overflow: hidden;
        display: block;
      }
      .menu-li:hover{
        span{
          display: block;
        }
      }
    }
  }
  .page-content{
    position: absolute;
    left: 240px;
    right: 0;
    top: 50px;
    bottom:0;
    width: auto;
    padding:0;
    box-sizing: border-box;
  }
  .uploader {
    width: 370px;
    margin: 0px auto;

    a {
      text-decoration: underline;
    }
  }

  /deep/.el-menu-item{
    height: 40px;
    line-height: 40px;
    i{
      font-size: 13px;
      //color: #999;
    }
  }
  /deep/.el-menu-item [class^=el-icon-]{
    width: 20px;
    margin-right: 0;
  }
</style>
